import { useRef } from "react";

import { FIXME } from "../../../shared/types";

const useScrollToInput = () => {
  const isIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent);
  };
  const inputRef: FIXME = useRef(null);

  const scrollToInput = () => {
    if (inputRef.current) {
      // const rect = inputRef.current.getBoundingClientRect();
      // window.scrollTo({
      //   top: rect.top + window.scrollY - 100,
      //   behavior: "smooth",
      // });
      // const { height: newHeight } = inputRef.current.getBoundingClientRect();
      // const heightDifference = Math.abs(window.innerHeight - newHeight);

      // alert(newHeight);
      if (isIOS()) {
        Object.assign(inputRef.current.style, {
          position: "fixed",
          bottom: "50%",
          left: "0",
          width: "100%",
          zIndex: "1000",
        });
      }

      // window.scrollTo({
      //   top: rect.top + window.scrollY - 100,
      //   behavior: "smooth",
      // });
    }
  };

  return [inputRef, scrollToInput];
};

export default useScrollToInput;
