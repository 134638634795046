import { createContext, useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

// import MainHeader from "../components/Layout/MainHeader";
// import MainNavigation from "../components/Layout/MainNavigation";
// import NewUpdate from "../components/Shared/newUpdate.tsx";
import { GET_MY_CLIQ } from "../graphql/my-cliq.graphql";
import { CREATE_USER_NOTIFICATION_TOKEN } from "../graphql/notifications.graphql.ts";
import { GET_USER } from "../graphql/users.graphql";
import MyCliqPage from "../pages/MyCliqPage";
// import NearMePage from "../pages/NearMePage";
import OnboardingPage from "../pages/OnboardingPage";
import {
  callNativeApp,
  callNativeAppAsync,
  handleNativeCallback,
  NativeAppActions,
} from "../shared/native-app-helpers";
import { parsePath } from "../shared/path-helpers";
import { FIXME } from "../shared/types";

import { AuthContext } from "./AuthProvider";
import { OnboardingContext } from "./OnboardingProvider";

export enum Screens {
  Chat = "Chat",
  Chats = "Chats",
  Club = "Club",
  ClubChats = "Club Chats",
  ClubJoinRequests = "Club Join Requests",
  ClubMembers = "Club Members",
  CreateClub = "Create Club",
  CreateEvent = "Create Event",
  EditClub = "Edit Club",
  EditEvent = "Edit Event",
  EditProfile = "Edit Profile",
  Event = "Event",
  EventAttendees = "Event Attendees",
  EventChats = "Event Chats",
  MyCLIQ = "My CLIQ",
  NearMe = "Near Me",
  Notifications = "Notifications",
  Profile = "Profile",
  Settings = "Settings",
  ChatInfo = "Chat Info",
}

type AppType = {
  build: string;
  goBack: FIXME;
  navigate: FIXME;
  refetch: FIXME;
  replace: FIXME;
  reset: FIXME;
  stack: FIXME;
  version: string;
};

export const AppContext = createContext<AppType>({
  build: "0",
  goBack: null,
  navigate: null,
  refetch: null,
  replace: null,
  reset: null,
  stack: [],
  version: "0.0.0",
});

export const AppProvider = ({ children }: FIXME) => {
  const [startScreen, setStartScreen] = useState<Screens.NearMe | Screens.MyCLIQ>(Screens.NearMe);
  const [screens, setScreens] = useState<FIXME>({});
  const [stack, setStack] = useState<FIXME>([]);

  const [build, setBuild] = useState("0");
  const [version, setVersion] = useState("0.0.0");

  const [saveUserNotificationsTokenMutation] = useMutation(CREATE_USER_NOTIFICATION_TOKEN);

  const { onboarded } = useContext(OnboardingContext);
  const { userId } = useContext(AuthContext);

  const { refetch: refetchMyCLIQ } = useQuery(GET_MY_CLIQ, {
    fetchPolicy: "cache-and-network",
  });
  const { refetch: refetchUser } = useQuery(GET_USER, {
    fetchPolicy: "cache-and-network",
    variables: { userId },
  });

  const saveNotificationToken = async () => {
    const { data } = await callNativeAppAsync({
      action: NativeAppActions.GET_NOTIFICATIONS_PERMISSIONS,
    });
    console.log({ data });
    console.log({ fcmToken: data.token });
    if (data.token) await saveUserNotificationsTokenMutation({ variables: { token: data.token } });
  };
  useEffect(() => {
    if (onboarded) {
      saveNotificationToken().then(() => console.log("Notification Token saved"));
    }
  }, [onboarded]);

  useEffect(() => {
    window.addEventListener("message", handleNativeCallback);
    document.addEventListener("message", handleNativeCallback);
    return () => {
      window.removeEventListener("message", handleNativeCallback);
      document.removeEventListener("message", handleNativeCallback);
    };
  }, []);

  useEffect(() => {
    const handleGetVersion = (evt: FIXME) => {
      try {
        const payload = JSON.parse(evt.data);
        const { action, data } = payload;
        if (action === NativeAppActions.GET_VERSION) {
          setBuild(data.build);
          setVersion(data.version);
        }
      } catch {
        //
      }
    };

    callNativeApp({ action: NativeAppActions.GET_VERSION });

    window.addEventListener("message", handleGetVersion);
    document.addEventListener("message", handleGetVersion);
    return () => {
      window.removeEventListener("message", handleGetVersion);
      document.removeEventListener("message", handleGetVersion);
    };
  }, []);

  useEffect(() => {
    const screens = children.map(
      ({ props: { element, hideMenus, name, path, ...props } }: FIXME) => ({
        element,
        hideMenus,
        name,
        path,
        ...props,
      })
    );
    setScreens(screens);
  }, [children]);

  const getMatch = (path: string) => {
    const matched = screens
      ?.map((screen: FIXME) => {
        const params = parsePath(screen.path, path);
        return params ? { ...screen, params } : null;
      })
      .filter((notnull: FIXME) => notnull);

    return matched[0];
  };

  const navigate = (path: string) => {
    console.log("##################### AppProvider");
    console.log("AppProvider navigate path ---", path);
    console.log("AppProvider navigate startScreen ---", startScreen);
    
    if (path === "/") {
      setStartScreen(Screens.NearMe);
      setStack([]);
      return;
    }

    if (path === "/my-cliq") {
      setStartScreen(Screens.MyCLIQ);
      setStack([]);
      return;
    }

    const matched = getMatch(path);
    if (matched) {
      const newStack = [...stack, matched];
      setStack(newStack);
    } else {
      console.log(`No match for ${path}`);
    }
  };

  const goBack = () => {
    const newStack = [...stack];

    const isChatPage = stack.some((v: any) => v.name === "Chat" || v.name === "Chats");
    if (isChatPage) {
      if (stack[0] && stack[0].name === "Chat") {
        navigate("/chats");
        return;
      }
    }

    newStack.pop();
    setStack(newStack);
  };

  const replace = (path: string) => {
    goBack();
    navigate(path);
  };

  const reset = (path: string) => {
    setStartScreen(Screens.NearMe);
    const matched = getMatch(path);
    if (matched) {
      const newStack = [matched];
      setStack(newStack);
    } else {
      console.log(`No match for ${path}`);
    }
  };

  const refetch = () => {
    refetchMyCLIQ();
    refetchUser();
  };

  useEffect(() => {
    if (screens.length > 0) {
      navigate(window.location.pathname);
    }
  }, [screens]);

  const value = {
    build,
    goBack,
    navigate,
    refetch,
    replace,
    reset,
    stack: [{ name: startScreen }, ...stack],
    version,
  };

  const SCREEN_WIDTH = window.innerWidth;

  if (!onboarded) {
    return <OnboardingPage />;
  }

  return (
    <AppContext.Provider value={value}>
      <>
        {/* <NewUpdate
          message="An updated version of the application has been released!!"
          message2='Tap the "Update Now" button to get started.'
        /> */}
        <div className="stack">
          {/* <div>{!stack[stack.length - 1]?.hideMenus && <MainHeader />}</div> */}
          <div className="stack-container">
            <div
              className="stack-wrapper"
              style={{
                left: `${0 - SCREEN_WIDTH * stack.length}px`,
                width: `${SCREEN_WIDTH * (stack.length + 1)}px`,
              }}
            >
              <div className="screen" style={{ width: SCREEN_WIDTH }}>
                {/* {startScreen === Screens.NearMe && <NearMePage />} */}
                {startScreen === Screens.MyCLIQ && <MyCliqPage />}
              </div>
              {stack.map((screen: FIXME, index: number) => (
                <div className="screen" key={`screen-${index}`} style={{ width: SCREEN_WIDTH }}>
                  <screen.element {...screen.props} {...screen.params} />
                </div>
              ))}
            </div>
          </div>
          {/* <div>{!stack[stack.length - 1]?.hideMenus && <MainNavigation />}</div> */}
        </div>
      </>
    </AppContext.Provider>
  );
};

export const AppScreen = (_: {
  defaultTab?: number;
  element: FIXME;
  hideMenus?: boolean;
  name: Screens;
  path: string;
}) => null;
